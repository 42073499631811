function App() {
  return (
        <div id="message">
          <h1>Ministério</h1>
          <h2>Ministério</h2>
          <h3>Ministério</h3>
          <h4>Ministério</h4>
          <h5>Ministério</h5>
          <h6>Ministério</h6>
          <p>Site ainda em desenvolvimento.</p>
          <p>Em desenvolvimento por <a href='https://eliasfpaiva.com.br' target='_blank' rel='noopener noreferrer'>Elias Paiva</a></p>
        </div>
  );
}

export default App;